import { React, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap'
import './FbType.scss';

const FbType = ({ string, delay }) => {

  // [animationFinished, setAnimatedFinished] = useState(false);

  const stringRef = useRef();

  useLayoutEffect(() => {

    function getRand(min, max) {
      return (Math.random() * (max - min) + min) / 100;
    }

    function fbType() {

      let typeString = string;

      const typeArray = [...typeString];

      let duration = 0;
      let buildText = stringRef.current.innerText;

      let textTimeline = gsap.timeline({ repeat: 0 });
      typeArray.forEach((obj, i) => {
        buildText += obj;
        // duration += getRand(12,24);
        duration += getRand(3, 12);
        textTimeline.set(stringRef.current, {
          innerText: buildText
        }, duration);
      });
    };

    setTimeout(fbType, delay);

  }, []);



  return (

    <div className="fb-type">
      <span ref={stringRef} className="code"></span><span className="cursor blink" >
        |
      </span>
    </div>

  );
};

FbType.defaultProps = {
  string: `hello world!`,
  delay: 1000
}

export default FbType;
