import { createContext, useState } from "react";

const WebsitesContext = createContext();

function Provider({ children }) {

  const [websites, setWebsites] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);

  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }


  const websitesArray = [
    {
      "id": 1,
      "vimeoID": "811196518",
      "url": "https://bee-agency.nl/",
      "title": "Bee Agency",
      "tagline": "Small Agency for Big Things",
      "images": [
        "bee-agency-1.jpg",
        "bee-agency-2.jpg",
        "bee-agency-3.jpg"
      ],
      "icon": "website-icons/beeagency.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Mariël Stapper",
          "role": "graphical design",
          "url": "https://stijlcabinet.com/"
        }
      ]
    },
    {
      "id": 2,
      "vimeoID": "811196547",
      "url": "https://boekhandeloverhetwater.nl/",
      "title": "Boekhandel over het water",
      "tagline": "De inspirerende boekhandel in Amsterdam-Noord",
      "images": [
        "boekhandel-over-het-water-1.jpg",
        "boekhandel-over-het-water-2.jpg",
        "boekhandel-over-het-water-3.jpg"
      ],
      "icon": "website-icons/boekhandeloverhetwater.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Mariël Stapper",
          "role": "graphical design",
          "url": "https://stijlcabinet.com/"
        }
      ]
    },
    {
      "id": 3,
      "vimeoID": "811196600",
      "url": "https://brandnewways.nu/",
      "title": "Brand New Ways",
      "tagline": "Flexibel Marketing Team",
      "images": [
        "brand-new-ways-1.jpg",
        "brand-new-ways-2.jpg",
        "brand-new-ways-3.jpg"
      ],
      "icon": "website-icons/brandnewways.png",
      "project": [
        "website development",
        "concept"
      ],
      "cocreation": [
        {
          "name": "Roderik Visser",
          "role": "concept & strategy",
          "url": "https://roderik.nu/"
        },
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 4,
      "vimeoID": "811196679",
      "url": "https://burohoning.nl/",
      "title": "Buro Honing",
      "tagline": "Merkidentiteiten en merkactivaties met aantrekkingskracht",
      "images": [
        "buro-honing-1.jpg",
        "buro-honing-2.jpg",
        "buro-honing-3.jpg"
      ],
      "icon": "website-icons/burohoning.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 5,
      "vimeoID": "811196717",
      "url": "https://davidslaw.nl/",
      "title": "Davids Advocaten",
      "tagline": "Dé specialist in herstructurering insolventie & litigation",
      "images": [
        "davidslaw-1.jpg",
        "davidslaw-2.jpg",
        "davidslaw-3.jpg"
      ],
      "icon": "website-icons/davidslaw.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 6,
      "vimeoID": "811196771",
      "url": "https://echtparket.nl/",
      "title": "Echtparket",
      "tagline": "Specialist in eiken vloeren",
      "images": [
        "echt-parket-1.jpg",
        "echt-parket-2.jpg",
        "echt-parket-3.jpg"
      ],
      "icon": "website-icons/echtparket.png",
      "project": [
        "website redesign"
      ],
      "cocreation": [
        {
          "name": "Sophie Polkamp",
          "role": "graphical design",
          "url": "https://sophiepolkamp.com/"
        }
      ]
    },
    {
      "id": 7,
      "vimeoID": "811196843",
      "url": "https://izipack.nl/",
      "title": "Izipack",
      "tagline": "Makkelijk pakketten versturen en ontvangen",
      "images": [
        "izipack-1.jpg",
        "izipack-2.jpg",
        "izipack-3.jpg"
      ],
      "icon": "website-icons/izipack.png",
      "project": [
        "website development",
        "website redesign"
      ],
      "cocreation": []
    },
    {
      "id": 8,
      "vimeoID": "811196907",
      "url": "https://jouwverslavingszorg.nl/",
      "title": "jouw verslavings­zorg",
      "tagline": "Je hebt een keuze",
      "images": [
        "jouw-verslavingszorg-1.jpg",
        "jouw-verslavingszorg-2.jpg",
        "jouw-verslavingszorg-3.jpg"
      ],
      "icon": "website-icons/jouwverslavingszorg.png",
      "project": [
        "website development",
        "logo animation"
      ],
      "cocreation": [
        {
          "name": "Roderik Visser",
          "role": "concept & strategy",
          "url": "https://roderik.nu/"
        },
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 9,
      "vimeoID": "811196946",
      "url": "https://kineticconsultancy.nl/",
      "title": "Kinetic Consultancy",
      "tagline": "Creating experiences, propelling business",
      "images": [
        "kinetic-consultancy-1.jpg",
        "kinetic-consultancy-2.jpg",
        "kinetic-consultancy-3.jpg"
      ],
      "icon": "website-icons/kineticconsultancy.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Roderik Visser",
          "role": "concept & strategy",
          "url": "https://roderik.nu/"
        },
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 10,
      "vimeoID": "811197066",
      "url": "https://www.knotsosquare.com/",
      "title": "Knotsosquare Crochet",
      "tagline": "Don't be a ⬚",
      "images": [
        "knotsosquare-1.jpg",
        "knotsosquare-2.jpg",
        "knotsosquare-3.jpg"
      ],
      "icon": "website-icons/knotsosquare.png",
      "project": [
        "website development",
        "graphical design"
      ],
      "cocreation": []
    },
    {
      "id": 11,
      "vimeoID": "811197143",
      "url": "https://kurkasa.com/",
      "title": "Kurkasa",
      "tagline": "maak een tuin van je tafel",
      "images": [
        "kurkasa-1.jpg",
        "kurkasa-2.jpg",
        "kurkasa-3.jpg"
      ],
      "icon": "website-icons/kurkasa.png",
      "project": [
        "website development",
        "graphical design"
      ],
      "cocreation": []
    },
    {
      "id": 12,
      "vimeoID": "811197259",
      "url": "https://leconcierge.nl/",
      "title": "Le Concierge",
      "tagline": "Full service creative agency",
      "images": [
        "le-concierge-1.jpg",
        "le-concierge-2.jpg",
        "le-concierge-3.jpg"
      ],
      "icon": "website-icons/leconcierge.png",
      "project": [
        "website development",
        "logo animation"
      ],
      "cocreation": []
    },
    {
      "id": 13,
      "vimeoID": "811197336",
      "url": "https://new-bees.org/",
      "title": "NewBees",
      "tagline": "Impact, Inclusie, Inburgering",
      "images": [
        "new-bees-org-1.jpg",
        "new-bees-org-2.jpg",
        "new-bees-org-3.jpg"
      ],
      "icon": "website-icons/newbeesorg.png",
      "project": [
        "website redesign"
      ],
      "cocreation": []
    },
    {
      "id": 14,
      "vimeoID": "811197405",
      "url": "https://propertypass.com/",
      "title": "PropertyPass",
      "tagline": "Het vastgoed paspoort",
      "images": [
        "propertypass-1.jpg",
        "propertypass-2.jpg",
        "propertypass-3.jpg"
      ],
      "icon": "website-icons/propertypass.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    },
    {
      "id": 15,
      "vimeoID": "811197453",
      "url": "https://stadelmann.ch/",
      "title": "Stadelmann",
      "tagline": "Advocaten aan de top van Europa",
      "images": [
        "stadelmann-1.jpg",
        "stadelmann-2.jpg",
        "stadelmann-3.jpg"
      ],
      "icon": "website-icons/stadelmann.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Fedde Dijkstra",
          "role": "graphical design",
          "url": "https://tioko.studio/"
        }
      ]
    },
    {
      "id": 16,
      "vimeoID": "811197570",
      "url": "https://theyardgroningen.nl/",
      "title": "The Yard Groningen",
      "tagline": "Spacious Green Living",
      "images": [
        "the-yard-groningen-1.jpg",
        "the-yard-groningen-2.jpg",
        "the-yard-groningen-3.jpg"
      ],
      "icon": "website-icons/theyardgroningen.png",
      "project": [
        "website development"
      ],
      "cocreation": [
        {
          "name": "Sjoerd Liefting",
          "role": "graphical design",
          "url": "https://burohoning.nl/"
        }
      ]
    }
  ];

  const fetchWebsites = () => {
    shuffleArray(websitesArray);
    setWebsites(websitesArray);
  };

  const openModal = (currentIndex) => {
    setModalOpen(currentIndex);
  }

  const closeModal = () => {
    setModalOpen(null);
  }

  const nextModal = (currentIndex) => {

    currentIndex < (websites.length - 1) ? setModalOpen(currentIndex + 1) : setModalOpen(0);
    // currentIndex > 0 ? setModalOpen(currentIndex - 1) : setModalOpen(websites.length - 1);

    // map over websites and update with new object with new title instead of old object
    // const mappedWebsites = websites.map(
    //   (website) => {
    //     return website;
    //   }
    // );
    // setWebsites(mappedWebsites);
  }

  const prevModal = (currentIndex) => {

    currentIndex > 0 ? setModalOpen(currentIndex - 1) : setModalOpen(websites.length - 1);

    // map over websites and update with new object with new title instead of old object
    // const mappedWebsites = websites.map(
    //   (website) => {
    //     return website;
    //   }
    // );
    // setWebsites(mappedWebsites);
  }

  const websitesAndFunctions = {
    websites,
    modalOpen,
    openModal,
    closeModal,
    nextModal,
    prevModal,
    fetchWebsites,
  };

  return (
    <WebsitesContext.Provider value={websitesAndFunctions} >
      {children}
    </WebsitesContext.Provider>
  );

}

export { Provider };
export default WebsitesContext;
