import { React, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap'
import '../scss/Hero.scss';

const Hero = () => {

  const firstStringRef = useRef();
  const secondStringRef = useRef();
  const thirdStringRef = useRef();
  const fourthStringRef = useRef();
  const codeBreakRef = useRef();
  const gd1Ref = useRef();
  const gd2Ref = useRef();
  const gd3Ref = useRef();
  const websitesRef = useRef();
  const visualsRef = useRef();
  const conceptsRef = useRef();

  useLayoutEffect(() => {

    function getRand(min, max) {
      return (Math.random() * (max - min) + min) / 100;
    }

    function fbType() {

      let typeString1 = `<`;
      let typeString2 = `studio`;
      let typeString3 = `="     ">`;
      let typeString4 = `fijnbesnaard`;

      const typeArray1 = [...typeString1];
      const typeArray2 = [...typeString2];
      const typeArray3 = [...typeString3];
      const typeArray4 = [...typeString4];

      let duration = 0;
      let buildText1 = firstStringRef.current.innerText;
      let buildText2 = secondStringRef.current.innerText;
      let buildText3 = thirdStringRef.current.innerText;
      let buildText4 = fourthStringRef.current.innerText;

      let textTimeline = gsap.timeline({ repeat: 0 });
      typeArray1.forEach((obj, i) => {
        buildText1 += obj;
        // duration += getRand(12,24);
        duration += getRand(3, 24);
        textTimeline.set(firstStringRef.current, {
          innerText: buildText1
        }, duration);
      });
      typeArray2.forEach((obj, i) => {
        buildText2 += obj;
        // duration += getRand(12,24);
        duration += getRand(3, 32);
        textTimeline.set(secondStringRef.current, {
          innerText: buildText2
        }, duration);
      });
      typeArray3.forEach((obj, i) => {
        buildText3 += obj;
        // duration += getRand(12,24);
        duration += getRand(3, 16);
        textTimeline.set(thirdStringRef.current, {
          innerText: buildText3
        }, duration,);
      });
      textTimeline.set(codeBreakRef.current, { display: 'block' });
      typeArray4.forEach((obj, i) => {
        buildText4 += obj;
        // duration += getRand(12,24);
        duration += getRand(3, 32);
        textTimeline.set(fourthStringRef.current, {
          innerText: buildText4
        }, duration);
      });
      textTimeline.to([websitesRef.current, visualsRef.current, conceptsRef.current], { duration: 0.75, opacity: 1, scale: 1, x: 0, y: 0, stagger: 0.5 }, ">+=0.75");
      textTimeline.set([gd1Ref.current, gd2Ref.current, gd3Ref.current], { display: "inline-flex" }, ">+=0.5");

    };

    setTimeout(fbType, 2000);

  }, []);



  return (

    <div className="hero">
      <div className="hero-content">

        <h1 id="page-title" className="visuallyhidden">studio fijnbesnaard websites, visuals, concepts</h1>
        <div className="fsb-leader" onClick={e => {
          let intro = document.getElementById("welcome");
          e.preventDefault();  // Stop Page Reloading
          intro && intro.scrollIntoView({ behavior: "smooth", block: "start" });
        }}>

          <div id="first-line"><span ref={firstStringRef} className="code"></span><span ref={secondStringRef} className="text"></span><span ref={thirdStringRef} className="code whitespace"></span></div>
          <br ref={codeBreakRef} className="code-break" />
          <div id="second-line"><span ref={fourthStringRef} className="glow"></span> </div>
          <div className="cursor blink" >
            |
          </div>
        </div>
        <div className="wvc" onClick={e => {
          let projects = document.getElementById("projects");
          e.preventDefault();  // Stop Page Reloading
          projects && projects.scrollIntoView({ behavior: "smooth", block: "start" });
        }}>
          <span ref={websitesRef} className="websites">websites</span>
          <span ref={visualsRef} className="visuals">visuals</span>
          <span ref={conceptsRef} className="concepts">concepts</span></div>
      </div>
      <a href="/" onClick={e => {
        let intro = document.getElementById("welcome");
        e.preventDefault();  // Stop Page Reloading
        intro && intro.scrollIntoView({ behavior: "smooth", block: "start" });
      }} className="going-down">
        <span ref={gd1Ref}>&gt;</span>
        <span ref={gd2Ref}>&gt;</span>
        <span ref={gd3Ref}>&gt;</span>
      </a>
    </div>

  );
};

export default Hero;
