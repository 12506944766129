import { React, useRef } from 'react';
import FbType from './FbType';
import Vimeo from '@u-wave/react-vimeo';
import './WebsiteSingle.scss';

const WebsiteSingle = ({ website, chroma }) => {

  const vimeoRef = useRef();

  let projectImages = [];
  if (website.images) {
    projectImages = website.images.map((image, index) => {
      // console.log(image);
      let imgSrc = require(`../images/${image}`);
      return (
        <img key={index} src={imgSrc} alt={website.title + '-' + index} className={`website-image transition rounded-2xl`} />
      )
    })
  }

  let cocreators = [];
  if (website.cocreation) {
    cocreators = website.cocreation.map((cocreator, index) => {
      return (
        <p key={index}><span className="role">{cocreator.role}</span> by <a href={cocreator.url} target="blank">{cocreator.name}</a></p>
      )
    })
  }



  return (

    <div className="website-single" >
      <div className="header">
        <h3 className="website-title">
          <FbType string={website.title} delay={500} />
        </h3>
        <div className="website-tagline">{website.tagline}</div>
      </div>
      <div className="project-images">

        {projectImages ? (
          <div className="images-grid">
            {projectImages}
          </div>
        ) : null}
      </div>
      {website.vimeoID ? (
        <div className="video-container rounded-2xl max-w-xl mx-auto p-1" style={{ backgroundColor: chroma }}>

          <Vimeo
            ref={vimeoRef}
            video={website.vimeoID}
            responsive
            width='576'
            height='324'
          />
        </div>
      ) : null}
      {cocreators.length ? (
        <div className="credits">

          <h4 className="credits-title">Credits</h4>
          {cocreators}
        </div>
      ) : null}
      <div className="link-out">
        <a href={website.url} target="blank">&gt;&gt; website</a>
      </div>
    </div>

  )
};

export default WebsiteSingle;
