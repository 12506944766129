import { React, useEffect } from 'react';
import WebsiteList from './components/WebsiteList';
import useWebsitesContext from './hooks/use-websites-content';
import Hero from './components/Hero';
import Intro from './components/Intro';
import './App.scss';

const App = () => {

  const { fetchWebsites } = useWebsitesContext();

  // run useEffect only once when component renders for the first time
  useEffect(() => {
    // setBooks to persisted books in FnbsnrdApi (db.json)
    fetchWebsites();
  }, []);

  return (
    <div>

      <Hero />

      <Intro />

      {/* PORTFOLIO */}
      <div className="portfolio-wrapper">

        <WebsiteList />
      </div>

      <div className="wrapper-footer" id="wrapper-footer">

        <div id="stickycontact">
          <span className="phone">t: <a href="tel:+31645462919">06 454 629 19</a></span>
          <span className="email">e: <a href="mailto:niels@fijnbesnaard.nl">niels@fijnbesnaard.nl</a></span>
        </div>

      </div>
    </div>
  );
};

export default App;
