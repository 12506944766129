import { React, useState, forwardRef } from 'react';
import '../scss/Intro.scss';
import niels from '../images/portrait-niels-hartog.jpg';
import niels1 from '../images/niels-1.jpg';
import niels2 from '../images/niels-2.jpg';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import clsx from 'clsx';
// import '../scss/Intro.scss';

const Intro = () => {

  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const aboutContent = () => {
    return (
      <div className="about-content">
        <h4 className="about-me">About me</h4>
        <p>I strongly believe in the power of openness, play and appreciation. Appreciation for eachother and the world around us.</p>

        <div className="about-image">
          <img src={niels1} alt="Niels heel ontspannen" />
        </div>

        <p>When not designing websites or collaborating with clients, I enjoy spending time with my family and friends, playing tennis and exploring new places (but I always seem to be drawn to Italy).</p>

        <div className="about-image">
          <img src={niels2} alt="Niels minder ontspannen" />
        </div>

        <p>I will most always agree to a game of pool or pingpong.</p>
      </div>
    )
  };
  const contactContent = (
    <div className="contact-content">
      {/* <div className="contact-image">
        <img src={niels3} alt="portrait of Niels Hartog" />
      </div> */}
      <div className="contact-details">
        <p className="name">Niels Hartog</p>
        <p className="phone">t: <a href="tel:+31645462919">06 454 629 19</a></p>
        <p className="email">e: <a href="mailto:niels@fijnbesnaard.nl">niels@fijnbesnaard.nl</a></p>
      </div>
    </div>
  );

  const BackdropUnstyled = forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
      <div
        className={clsx({ 'MuiBackdrop-open': open }, className)}
        ref={ref}
        {...other}
      />
    );
  });

  BackdropUnstyled.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
  };

  const Modal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    // align-items: center;
    padding-top: 4rem;
    justify-content: center;
  `;

  const Backdrop = styled(BackdropUnstyled)`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
  `;

  return (

    <div id="welcome" className="intro">
      <div className="intro-content">
        <div className="hello-sm">Hello there!</div>
        <div className="intro-image">
          <img src={niels} alt="portrait of Niels Hartog" />
        </div>
        <div className="intro-text">
          <div className="hello">Hi there!</div>
          <p>I'm Niels, an all-round web developer with over a decade of experience creating websites.</p>
          <p>I specialize in concise storytelling and easy-to-maintain websites.</p>
          <p>Don't hesitate to get in touch with me, I am always open to new and meaningful projects.</p>

          <div className="cta-buttons">
            <span className="intro-contact" onClick={() => setContactModalOpen(!contactModalOpen)}>CONTACT
            </span>
            <Modal
              aria-labelledby="Contact"
              open={contactModalOpen}
              onClose={() => setContactModalOpen(false)}
              slots={{ backdrop: Backdrop }}
              className="contact-modal"
            >
              {/* <Box sx={{ overflow: 'scroll', right: 0, top: 0, left: 0 }} className="mui-fixed"> */}
              <Box>
                <div className="modal-bg"></div>
                <div className="icon-close" onClick={() => setContactModalOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" /></svg></div>
                {contactContent}
              </Box>
            </Modal>
            &nbsp;|
            <span className="intro-about" onClick={() => setAboutModalOpen(!aboutModalOpen)}>&nbsp;ABOUT
            </span>
            <Modal
              aria-labelledby="About"
              open={aboutModalOpen}
              onClose={() => setAboutModalOpen(false)}
              slots={{ backdrop: Backdrop }}
              className="about-modal"
            >
              {/* <Box sx={{ overflow: 'scroll', right: 0, top: 0, left: 0 }} className="mui-fixed"> */}
              <Box>
                <div className="modal-bg"></div>
                <div className="icon-close" onClick={() => setAboutModalOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" /></svg></div>
                {aboutContent()}
              </Box>
            </Modal>
            &nbsp;|
            <span className="intro-projects" onClick={e => {
              let projects = document.getElementById("projects");
              e.preventDefault();  // Stop Page Reloading
              projects && projects.scrollIntoView({ behavior: "smooth", block: "start" });
            }}>&nbsp;PROJECTS
            </span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Intro;
