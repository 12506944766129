import { React } from 'react';
import WebsiteShow from './WebsiteShow';
import './WebsiteList.scss';
import chroma from "chroma-js";
import useWebsitesContext from '../hooks/use-websites-content';

const WebsiteList = () => {

  const { websites, modalOpen, nextModal } = useWebsitesContext();

  // const kColors = chroma.scale(['#FFDF00', '#FFFFC2', '#FF7722']).mode('lab').colors(websites.length);
  const kColors = chroma.scale(['#FFDF00', '#FF7722']).mode('lab').colors(websites.length);

  const renderedWebsites = websites.map((website, index) => {

    return (
      <div key={website.id} className={`card website rounded-2xl`}>
        <WebsiteShow
          website={website}
          chroma={kColors[index]}
          isModalOpen={index === modalOpen ? true : false}
          index={index}
          total={websites.length}
          nextModal={nextModal}
        />
      </div>
    );
  });

  return (
    <div id="projects" className="website-list container mx-auto">
      {renderedWebsites}
    </div>
  );
};

export default WebsiteList;
