import React, { forwardRef } from 'react';
import useWebsitesContext from '../hooks/use-websites-content';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import WebsiteSingle from './WebsiteSingle';
import clsx from 'clsx';
import './WebsiteShow.scss';



const BackdropUnstyled = forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  // align-items: center;
  padding-top: 4rem;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
`;

const WebsiteShow = ({ website, chroma, isModalOpen, index }) => {

  const { openModal, closeModal, nextModal, prevModal } = useWebsitesContext();

  // const handleClose = () => setOpen(false);
  const handleClose = () => closeModal();

  const iconSrc = require(`../images/${website.icon}`);

  const projectActivities = website.project.map((activity, index) => {
    return (
      <li key={index} className="project-activity">{activity}</li>
    )
  })


  return (

    <div>
      <div className="website-card" onClick={() => openModal(index)}>

        <div className="card-badge">
          <img src={iconSrc} alt={website.title + ' icon'} className={`website-image`} />
        </div>
        <h3 className="website-title">{website.title}</h3>
        <ul className="project-activities">
          {projectActivities}
        </ul>

        <div className="cta">
          <span>&gt;</span>
        </div>
      </div>

      <Modal
        aria-labelledby={website.title}
        aria-describedby={website.tagline}
        open={isModalOpen}
        onClose={handleClose}
        slots={{ backdrop: Backdrop }}
        website={website}
      >
        {/* <Box sx={{ overflow: 'scroll', right: 0, top: 0, left: 0 }} className="mui-fixed"> */}
        <Box>
          <div className="modal-bg"></div>
          <div className="icon-close" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" /></svg></div>
          <WebsiteSingle website={website} />
          <div className="modalnav">
            <div onClick={() => prevModal(index)} className="prev btn-nav">PREV</div>
            |
            <div onClick={() => nextModal(index)} className="next btn-nav">NEXT</div>
          </div>
        </Box>
      </Modal>

    </div>

  );
};

export default WebsiteShow;
